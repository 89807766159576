import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = false; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    if (payload) {
      // console.log('loginRequest', payload);
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
        profile: 'Profile'
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile'
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    // console.log('takeEveryLoginSuccess', payload);
    localStorage.setItem('id_token', payload.payload.token);
    localStorage.setItem('tipo_usuario', payload.payload.tipo);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    let payload = {
      token: localStorage.getItem('id_token'),
      tipo: localStorage.getItem('tipo_usuario')
    };
    // console.log('checkauth', payload);
    if (payload.token && payload.tipo) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
        profile: 'Profile'
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
