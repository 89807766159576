import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
const DashboardADM = lazy(() => import('./containers/DashboardADM/Dashboard'));

const NoMatch = lazy(() => import('./containers/Pages/404/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
];

function PrivateRouteADM({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const tipo = useSelector((state) => state.Auth.tipoUsuario);
  
  if (isLoggedIn && tipo === 'ADMIN') 
    return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRouteADM path="/adm">
              <DashboardADM />
            </PrivateRouteADM>

            <Route component={NoMatch} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
